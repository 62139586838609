import Service, { inject as service } from '@ember/service';
import { A } from '@ember/array';
import ProjectDirectorySyncOperation from 'seshy/models/project-directory-sync-operation';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';

export default class ProjectUploadManagerService extends Service {
  @service projectDirectoryWatcher;
  @service store;
  @service currentUser;
  @service projectVersionRepository;
  @service seshyDir;
  @service settings;
  @service betaFeatures;

  @tracked operations = A([]);

  // This is the amount of time that the watcher will wait after noticing that a
  // project data file has changed before firing our callback. This allows the file
  // system to "settle" before we start an upload. Not sure that this is the right
  // value for this timeout, or even if it's the right approach to the problem.
  watcherTimeout = 3000;

  start() {
    //console.log('projectUploadManager.start');
    let pathsToWatch = this.settings.pathsToWatch();
    //console.log('projectUploadManager.start', pathsToWatch);
    this.projectDirectoryWatcher.start(
      pathsToWatch,
      this.watcherTimeout,
      this.projectUpdated.bind(this)
    );
  }

  stop() {
    //console.log('projectUploadManager.stop');
    this.projectDirectoryWatcher.stop();
  }

  pausePath(projectPath) {
    this.projectDirectoryWatcher.pausePath(projectPath);
  }

  unpausePath(projectPath) {
    this.projectDirectoryWatcher.unpausePath(projectPath);
  }

  async projectUpdated(projectData) {
    console.debug(
      'we just noticed a project update and the projectData = ',
      projectData
    );
    // Here we lookup a model instance from the container instead of doing `new ProjectDownloadOperation`.
    // This allows us to use services inside the model, which allows us to use the settings service to
    // allow the user to set concurrency levels for file downloads.
    let applicationInstance = getOwner(this);
    let operationName = 'operation:project-directory-sync';
    if(this.betaFeatures.v2SyncEnabled){
      operationName = 'operation:project-directory-sync-v2';
    }
    var operation = applicationInstance.lookup(operationName);
    // Now we call a customInit method to set stuff that we used to pass to the constructor
    operation.customInit(
      projectData,
      this.store,
      this.currentUser.user.primaryTeam,
      this.projectVersionRepository,
      this.currentUser.user
    );

    this.operations.pushObject(operation);

    try{
      var operationData = await this.projectUpload.perform(operation);

      if (operationData) {
        this.projectVersionRepository.pushProjectData(
          operationData.project,
          operationData.projectVersion,
          operationData.localPath
        );
      }
    }catch(error){
      console.error('caught an error in the upload manager');
      console.error(error);
    }finally{
      this.operations.removeObject(operation);
    }

  }

  // This is kind of brute force defensive programming. It's an explicit bottleneck
  // to make sure we only ever try to create one project at a time. Sometimes we'll
  // get multiple notifications about a single project at once, and if they happen
  // close enough to each other we end up making duplicat projects. This prevents that.
  // Ideally we could do maxConcurrncy of "1 per distinct file path" but this will do for now.
  prepareOperationTask = task(
    { maxConcurrency: 1, enqueue: true },
    async (operation) => {
      await operation.prepare();
    }
  );

  // TODO : this is the most minimal change I could make to add some concurrency control to uploads.
  // There are probably better ways to handle this.
  projectUpload = task(
    { maxConcurrency: this.settings.get('projectUploadMax'), enqueue: true },
    async (operation) => {
      await this.prepareOperationTask.perform(operation);
      //console.log('starting project upload');
      var operationData = await operation.sync();
      //console.log('ending project upload');
      return operationData;
    }
  );

  operationForProjectId(projectId) {
    for (const operation of this.operations) {
      if (operation.project && operation.project.id == projectId) {
        return operation;
      }
    }
  }
}
