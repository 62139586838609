/* import __COLOCATED_TEMPLATE__ from './seshy-folder-open-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SeshyFolderOpenButtonComponent extends Component {
  @service currentUser;

  fs = require('fs');
  pathLib = require('path');

  get buttonText() {
    return this.args.buttonText || 'Open';
  }

  @action
  async openSeshyDir() {
    var path = this.args.folder;
    if (!this.fs.existsSync(path)) {
      this.fs.mkdirSync(path, { recursive: true, mode: 0o744 });
    }
    if (this.args.mkdirs) {
      console.log('currentUser = ', this.currentUser);
      var teams = await this.currentUser.get('teams');
      console.log('teams = ', teams);
      for (let i = 0, len = teams.length; i < len; i++) {
        var team = teams.objectAt(i);
        console.log('team = ', team);
        path = this.args.folder;
        if (team) {
          path = path + this.pathLib.sep + team.get('name');
        }
        if (!this.fs.existsSync(path)) {
          // TODO: We may not want to make team dirs if people have set a custom seshy dir?
          this.fs.mkdirSync(path, { recursive: true, mode: 0o744 });
        }
      }
    }

    var path = this.args.folder;
    const { ipcRenderer } = require('electron');
    await ipcRenderer.invoke('open-path', path);
  }
}
