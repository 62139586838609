import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthenticatedTeamsShowIndexRoute extends BaseRoute {
  @service store;

  async model(params) {
    console.log('params = ', params);
    let team = this.modelFor('authenticated.teams.show');
    let projects = this.store.query('project', {
      filter: {
        teamId: team.get('id'),
      },
      sort: '-latestVersionUpdatedAt',
      include:
        'owning-team,teams,user,updator,current-bounce,current-project-version,current-project-version.bounces',
    });
    let users = this.store.query('user', {
      filter: {
        team_id: team.id,
        //search: this.searchValue,
      },
    });

    let comments = this.store.query('comment', {
      filter: { team_id: team.id, activeWithinDays: 31 },
      //page: {size: 25},
      sort: '-createdAt',
      include: 'comment,comments,project,project-version,bounce,user',
    });

    let projectVersions = this.store.query('project-version', {
      filter: { team_id: team.id },
      page: { size: 25 },
      sort: '-createdAt',
      include: 'bounces,project,user',
    });

    return hash({ team, projects, users, comments, projectVersions });
  }
}
