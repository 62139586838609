import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedTeamsShowProjectsListIndexRoute extends BaseRoute {
  @service store;

  async model(params) {
    // TODO: This seems like kind of a hacky way for this to work, but it was the first way that I found
    // that would allow live updates to work as we encounter new projects that we need to deal with.
    let team = this.modelFor('authenticated.teams.show');
    let projects = await this.store.query('project', {
      filter: {
        teamId: team.get('id'),
      },
      sort: '-latestVersionUpdatedAt',
      include:
        'owning-team,teams,user,updator,current-bounce,current-project-version,current-project-version.bounces',
    });
    team.set('projects', projects);
    return { team };
  }

  @action
  routeRefresh() {
    this.refresh();
  }
}
