import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class BetaFeaturesService extends Service {
  @service currentUser;
  /*
  @tracked betaUsers = [
    'jeremy@octolabs.com',
    'jeremy@seshy.me',
    'jeremy+t2@seshy.me',
    'chrisrtubbs@gmail.com',
    'walrusmuse@gmail.com',
    'michael.gorsuch@gmail.com'
  ];
  */

  enabled(featureName) {
    //return false;
    //console.log('enabled', featureName);
    if (!this.currentUser.user) {
      return false;
    }
    return this.currentUser.user.betaFeatures?.indexOf(featureName) >= 0;
  }

  get commentsEnabled() {
    //console.log('commentsEnabled?')
    return this.enabled('comments');
  }

  get v2SyncEnabled() {
    //console.log('commentsEnabled?')
    return this.enabled('v2-sync');
  }
}
