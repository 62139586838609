import Model, { attr, hasMany } from '@ember-data/model';
import { capitalize } from '@ember/string';

export default class TeamModel extends Model {
  @attr('string') name;
  @attr('string') obfuscatedId;
  @attr currentUserRoles;
  @attr projectsCount;
  @attr projectsSizeInBytes;
  @attr storagePoolInBytes;
  @attr storagePoolUsedSizeInBytes;
  @attr labelString;
  @attr('string') currentSubscriptionProductId;
  @attr('string') currentSubscriptionCoveringTeamName;
  @attr currentSubscriptionCoveredTeams;

  @hasMany('team-project', { inverse: 'team', async: false }) teamProjects;
  @hasMany('project', { inverse: 'team', async: false }) projects;
  @hasMany('user', { inverse: 'teams', async: false }) users;

  get storagePoolPercentUsed(){
    return Math.round(this.storagePoolUsedSizeInBytes / this.storagePoolInBytes * 100);
  }

  get storagePoolRemaining(){
    let available = this.storagePoolInBytes - this.storagePoolUsedSizeInBytes;
    if(available < 0){
      return 0
    }
    return available;
  }

  get currentSubscriptionProductIdCapitalized(){
    return capitalize(this.currentSubscriptionProductId)
  }

  get currentSubscriptionIsCoveringMultipleTeams(){
    return this.currentSubscriptionCoveredTeams.length > 1;
  }

  get currentSubscriptionCoveredTeamsCount(){
    return this.currentSubscriptionCoveredTeams.length;
  }

  get currentSubscriptionCoveredOtherTeamsCount(){
    return this.currentSubscriptionCoveredTeams.length - 1;
  }
}
