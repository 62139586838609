/* import __COLOCATED_TEMPLATE__ from './project-version-list-item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ProjectVersionListItemComponent extends Component {
  @service projectVersionRepository;
  @service projectUploadManager;
  @service projectDownloadManager;
  @service currentUser;
  @service platform;

  @tracked showingDescription = false;

  @tracked isExpanded = false;

  @action
  async toggleExpansion(e) {
    e.preventDefault();
    this.isExpanded = !this.isExpanded;
    return false;
  }

  get isCurrent() {
    var ourChecksum = this.args.projectVersion.projectDataChecksum;
    var repoChecksum = this.projectVersionRepository.versionFor(
      this.args.project.id
    );
    return ourChecksum == repoChecksum;
  }

  get uploadOperation() {
    if (!this.platform.isNative) {
      return null;
    }
    if (this.args.project) {
      var operation = this.projectUploadManager.operationForProjectId(
        this.args.project.id
      );
      //console.log('uploadOperation = ', operation);
      if (
        operation &&
        operation.projectVersion &&
        operation.projectVersion.projectDataChecksum ==
          this.args.projectVersion.projectDataChecksum
      ) {
        return operation;
      }
    }
    //console.log('we do not have a project', this.args.project);
    return null;
  }

  get downloadOperation() {
    if (this.args.project && this.args.projectVersion) {
      var operation =
        this.projectDownloadManager.operationForProjectIdAndChecksum(
          this.args.project.id,
          this.args.projectVersion.projectDataChecksum
        );
      //console.log('downloadOperation = ', operation);
      return operation;
    }
    //console.log(
    //'we do not have a project and/or version for downloadOperation = ',
    //this.args.project
    //);
    return null;
  }

  @action
  showDescription() {
    this.showingDescription = true;
  }

  @action
  hideDescription() {
    this.showingDescription = false;
  }

  @action
  async removeBrandNewProjectVersion() {
    this.args.projectVersion.set('brandNewProjectVersion', null);
    var project = await this.args.projectVersion.get('project');
    if (project) {
      project.set('brandNewProjectVersion', null);
    }
    await this.args.projectVersion.reload();
  }
}
