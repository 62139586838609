/* import __COLOCATED_TEMPLATE__ from './project-destroyer.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import canUserManageProject from 'seshy/utils/can-user-manage-project';

export default class ProjectDestroyerComponent extends Component {
  @service currentUser;
  @service projectVersionRepository;
  @service router;

  @tracked isDestroying = false;
  @tracked isDestroyingLocal = false;
  @tracked isDestroyingCloud = false;
  @tracked projectNameConfirmation = '';
  @tracked hasLocalProject = true;
  @tracked localProjectPath = '';

  @tracked errorMessage = null;

  get hasError() {
    return !!this.errorMessage;
  }

  get canManageProject() {
    return canUserManageProject(this.currentUser, this.args.project);
  }

  @action
  async checkLocalProject() {
    var projectData = this.projectVersionRepository.dataFor(
      this.args.project.id
    );
    console.log('project data = ', projectData);
    if (projectData) {
      const fs = require('fs');
      this.localProjectPath = projectData.localPath;
      if (fs.existsSync(projectData.localPath)) {
        this.hasLocalProject = true;
      } else {
        this.hasLocalProject = false;
      }
    } else {
      this.hasLocalProject = false;
    }
  }

  get projectNameConfirmed() {
    return this.projectNameConfirmation == 'delete forever';
  }

  get projectNameNotConfirmed() {
    return !this.projectNameConfirmed;
  }

  @action
  startDestroying() {
    console.log('startDestroying');
    this.isDestroying = true;
  }

  @action
  cancel() {
    this.isDestroying = false;
  }

  @action
  async finishDestroying() {
    this.isDestroyingCloud = true;
    console.log('finishDestroying');
    let project = this.args.project;
    try {
      var projectId = project.get('id');
      await project.destroyRecord();

      var projectData = this.projectVersionRepository.dataFor(projectId);
      if (projectData) {
        this.projectVersionRepository.removeProjectDataByLocalPath(
          projectData.localPath,
          { skipSave: true }
        );
      }

      let team = this.currentUser.get('currentTeam');
      this.isDestroyingCloud = false;
      if (team) {
        this.router.transitionTo('authenticated.teams.show.projects.list');
      } else {
        this.router.transitionTo('authenticated.index');
      }
    } catch (error) {
      console.log('caught an error', error);
      this.isDestroyingCloud = false;
      let errorCause = error.errors[0];

      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  @action
  async openParentFolder() {
    const { ipcRenderer } = require('electron');
    await ipcRenderer.invoke('show-item-in-folder', this.localProjectPath);
  }

  @action
  async deleteLocalCopy() {
    this.isDestroyingLocal = true;

    console.log('delete local copy');
    const shell = require('electron').shell;
    const fs = require('fs');
    try {
      let trashData = await shell.trashItem(this.localProjectPath);
      console.log('trashData = ', trashData);
    } catch (err) {
      if (!fs.existsSync(this.localProjectPath)) {
        console.log('It seems the local copy was deleted outside of Seshy.');
      } else {
        throw err;
      }
    }

    this.projectVersionRepository.removeProjectDataByLocalPath(
      this.localProjectPath,
      { skipSave: true }
    );

    // We delay checking again because it takes a few seconds for the
    // upload manager to notice the change and remove it from the repo.
    later(() => {
      this.checkLocalProject();
      this.isDestroyingLocal = false;
    }, 3000);
  }

  setForbiddenMessage() {
    this.errorMessage =
      "You don't seem to be a Team Admin for the team that owns this project.";
  }

  setUnknownErrorMessage() {
    this.errorMessage =
      'We ran into an unexpected error while trying to delete this project. Please try again.';
  }
}
