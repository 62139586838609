import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class ProjectVersionModel extends Model {
  @attr('string') projectDataChecksum;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('boolean') uploadComplete;
  @attr('string') description;

  // Read only attributes
  @attr versionNumber;
  @attr uploadCompletedAt;
  @attr sizeInBytes;

  @belongsTo('project', { inverse: 'projectVersions', async: false }) project;
  @belongsTo('user', { inverse: null, async: false }) user;
  @hasMany('project-version-file-version', {
    inverse: 'projectVersion',
    async: false,
  })
  projectVersionFileVersions;
  @hasMany('project-file-version', { inverse: 'projectVersion', async: false })
  projectFileVersions;
  @hasMany('bounce', { inverse: 'projectVersion', async: false }) bounces;
  @hasMany('comment', { inverse: 'projectVersion', async: false }) comments;

  get latestBounce() {
    for (let i = 0, len = this.bounces.length; i < len; i++) {
      let bounce = this.bounces.at(i);
      if (bounce.uploadComplete) {
        return bounce;
      }
    }
    return null;
  }

  get createdAtISO() {
    return this.createdAt?.toISOString();
  }

  get modelId() {
    return `project-version-${this.id}`;
  }

  get hasDescription(){
    return (this.description != null && this.description != "")
  }
}
