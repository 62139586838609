import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class ProjectModel extends Model {
  @attr('string') name;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('boolean') archived;
  @attr('string') daw;

  // Read only attributes
  @attr latestProjectDataChecksum;
  @attr latestVersionSizeInBytes;
  @attr latestVersionUpdatedAt;
  @attr latestVersionDescription;
  @attr versionNumber;
  @attr sizeInBytes;
  @attr archivedAt;
  @attr teamId;

  // Not sure we need this...
  // Maybe the real owner should be a user?
  @belongsTo('team', { inverse: null, async: false }) owningTeam;
  @belongsTo('user', { inverse: null, async: false }) user;
  @belongsTo('user', { inverse: null, async: false }) updator;
  @belongsTo('bounce', { inverse: null, async: false }) currentBounce;
  @belongsTo('projectVersion', { inverse: null, async: false })
  currentProjectVersion;

  @hasMany('team-project', { inverse: 'project', async: false }) teamProjects;
  @hasMany('team', { inverse: 'projects', async: false }) teams;
  @hasMany('comment', { inverse: 'project', async: false }) comments;

  @hasMany('project-version', { inverse: 'project', async: false })
  projectVersions;

  @tracked
  fileUploadErrorFiles = A([]);

  // We have to do this so that images can be fingerprinted
  icons = {
    'logic-pro-x': '/images/logic-pro-x.png',
    'ableton-live': '/images/ableton-live.png',
    'avid-pro-tools': '/images/avid-pro-tools.png',
    'bitwig-studio': '/images/bitwig-studio.png',
    'fl-studio': '/images/fl-studio.png',
    reaper: '/images/reaper.png',
  };

  get firstTeam() {
    console.log('firstTeam ===', this.teams.objectAt(0).get('name'));
    return this.teams.objectAt(0);
  }

  get icon() {
    let imgString = this.icons[this.daw];
    return imgString;
  }

  get hasLatestVersionDescription(){
    return (this.latestVersionDescription != null && this.latestVersionDescription != "")
  }

  get displayName() {
    if (this.name.match(/.logicx$/)) {
      return this.name.replace(/.logicx$/, '');
    } else if (this.daw == 'ableton-live') {
      return this.name.replace(/ Project$/, '');
    } else {
      return this.name;
    }
  }

  get suffix() {
    if (this.name.match(/.logicx$/)) {
      return '.logicx';
    } else {
      return ' Project';
    }
  }

  get modelId() {
    return `project-${this.id}`;
  }
}
