import LocalFile from 'seshy/models/local-file';

export default class LocalReaperFileModel extends LocalFile {
  static glob = window.requireNode('glob-promise');
  static crypto = window.require('crypto');

  static dawName = 'reaper';

  // we don't want the backup dir
  static backupFragment = 'auto-backups';

  static excludedFilesRegex = new RegExp(this.backupFragment);

  // TODO
  static projectDataRegex = /(.*\/([^\/]*))\/[^\/]*.RPP$/;

  static projectDataFileGlob = '/*.RPP';

  static async buildFileList(projectPath) {
    // TODO: This is the naive first pass at slurping up a reaper project. Is this sufficient?
    var files = await this.glob('{*,.*}', {
      nodir: false,
      cwd: projectPath,
      absolute: true,
    });

    var filteredFiles = [];
    for (const file of files) {
      if (!file.match(this.excludedFilesRegex)) {
        filteredFiles.push(file);
      }
    }
    return filteredFiles;
  }

  static async calculateProjectFingerprint(projectPath) {
    const hash = this.crypto.createHash('sha256');
    var projectDataFiles = await this.glob(
      projectPath + this.projectDataFileGlob
    );
    for (const file of projectDataFiles) {
      var localDataFile = new LocalFile(file);
      var fingerprint = await localDataFile.fingerprint();
      hash.update(fingerprint);
    }
    return new Promise((resolve, reject) => {
      resolve(hash.digest('hex'));
    });
  }

  static getFirstProjectDataFile(projectPath){
    // TODO: We can't really identify the directory without the .ptx file being there...
    /*
    console.log('in getFirstProjectDataFile() ', projectPath);
    let theGlob = projectPath + this.projectDataFileGlob;
    console.log('theGlob = ', theGlob);
    let projectDataFiles =  this.fastGlob.sync(theGlob);
    console.log('in getFirstProjectDataFile() the projectDataFiles = ', projectDataFiles);
    if(projectDataFiles.length){
      return projectDataFiles[0];
    }
    */
  }

  projectName() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectName = match[2];
    return projectName;
  }

  projectPath() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectPath = match[1];
    return projectPath;
  }

  isProjectDataFiile() {
    return (
      this.path.match(this.constructor.projectDataRegex) &&
      !this.path.match(this.constructor.excludedFilesRegex)
    );
  }

  isTopLevelProjectDirectory(){
    return false;
    // TODO: We can't really identify the directory without the .ptx file being there...
    /*
    if(
      this.path.match(this.constructor.projectDirectoryRegex) &&
      !this.path.match(this.constructor.excludedFilesRegex)
    ){
      return true;
    }else{
      return false;
    }
    */
  }
}
