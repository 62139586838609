import Service, { inject as service } from '@ember/service';
import ActionCable from 'actioncable';
import ENV from 'seshy/config/environment';
import { pushPayload } from 'seshy/utils/push-payload';

export default class CableService extends Service {
  @service session;
  @service store;
  @service router;

  startSubscriptions() {
    this.cable = ActionCable.createConsumer(
      ENV.api.wshost +
        '/cable?token=' +
        this.session.data.authenticated.access_token
    );
  }

  subscribe(...args) {
    return this.cable.subscriptions.create(...args);
  }

  subscribe(channel, query, callback) {
    //console.log('calling subscribeForRefresh', channel, query)
    return this.cable.subscriptions.create(
      { channel: channel, ...query },
      {
        connected: () => {
          //console.debug('connected to ', channel, query);
        },

        rejected: () => {
          //console.debug('rejected from ', channel, query);
        },

        disconnected: () => {
          //console.debug('disconnected from ', channel, query);
        },

        received: async (data) => {
          //console.debug('received data on channel', channel, data);
          //this.router.refresh();
          //console.debug('callback = ', callback);
          callback();
        },
      }
    );
  }

  unsubscribe(subscription) {
    if (subscription) {
      this.cable.subscriptions.remove(subscription);
    } else {
      console.error(
        'we tried to unsubscribe from a subscription that does not exist'
      );
    }
  }
}
