import { htmlSafe } from '@ember/template';
import LocalFile from 'seshy/models/local-file';
import FileUploadOperation from 'seshy/models/file-upload-operation';
import MultipartFileUploadOperation from 'seshy/models/multipart-file-upload-operation';
import EmptyFileError from '../errors/empty-file-error';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';

export default class ProjectFileSyncOperationV2Model {
  @tracked progress = 0;

  fs = window.require('fs');

  constructor(filePath, project, projectPath, projectVersion, store, projectFileVersionId) {
    this.filePath = filePath;
    this.project = project;
    this.projectPath = projectPath;
    this.projectVersion = projectVersion;
    this.store = store;
    this.projectFilePath = this.filePath.replace(this.projectPath + '/', '');
    this.projectFileVersionId = projectFileVersionId
  }

  log(...args) {
    if (false) {
      console.log(args);
    }
  }

  async sync() {
    console.log('starting sync for file: ', this.filePath);

    if (!this.fs.existsSync(this.filePath)) {
      console.log('the file disappeared', this.filePath);
      this.progress = 100;

      await timeout(100); // we do a smol timeout to allow the progress bar to update
      return;
    }

    var localFile = new LocalFile(this.filePath);
    this.progress = 2.5;
    var checksum = await localFile.fingerprint();

    var projectFileVersion = await this.store.find('project-file-version', this.projectFileVersionId)

    this.progress = 10;

    if (!projectFileVersion.get('uploadComplete')) {
      var uploadStatus = await this.doUpload(projectFileVersion, checksum);
      //console.log('uploadStatus = ', uploadStatus);
      if (uploadStatus >= 200 && uploadStatus <= 299) {
        projectFileVersion.set('uploadComplete', true);
        await projectFileVersion.save();
      } else {
        console.error('we could not upload a file', uploadStatus);
      }
    }

    this.progress = 95;

    var projectVersionFileVersion =
      await this.findOrCreateProjectVersionFileVersion(
        projectFileVersion,
        this.projectVersion
      );

    this.progress = 100;

    await timeout(100); // we do a smol timeout to allow the progress bar to update
  }

  async doUpload(projectFileVersion, checksum) {
    console.log('starting doUpload', this.filePath);
    if (this.fs.lstatSync(this.filePath).isDirectory()) {
      //console.log('this is a dir, we can not doUpload', this.filePath);
      // Here we just act like the upload has worked. Maybe not the best way?
      return 200;
    }
    var stats = this.fs.statSync(this.filePath)
    var fileSizeInBytes = stats.size;

    var operation = null;
    // For files bigger than 1.9GB we use the MultipartFileUploadOperation.
    // I'm not entirely sure that checksums are working exactly right with this
    // uploader, so for now I'm only using it on files that are too big to upload
    // in one chunk with the normal uploader.
    // The one-shot uploader also seems to be a bit faster with smallish files. So...
    const multiPartTriggerSize = 1.9 * 1024 * 1024 * 1024;
    //const multiPartTriggerSize = 1;
    if(fileSizeInBytes > multiPartTriggerSize){
      //console.log('doing a MultipartFileUploadOperation')
      operation = new MultipartFileUploadOperation(
        this.filePath,
        projectFileVersion,
        checksum,
        this.store
      );
    }else{
      operation = new FileUploadOperation(
        this.filePath,
        projectFileVersion,
        checksum,
        this.store
      );
    }
    var uploadStatus = await operation.upload(
      this.updateUploadProgress.bind(this)
    );
    console.log('we received uploadStatus of ', uploadStatus);
    return uploadStatus;
  }

  updateUploadProgress(progress) {
    console.log('updating progress ', progress, this.filePath);
    // This calculation is a little weird since we're trying to blend upload progress with
    // other record keeping to present an overal view of progress. We manually update to
    // 10% done as we're fingerprinting and finding the projectFile and projectFileVersion.
    // and then 10% is reserved for post-upload bookkeeping.
    this.progress = 10 + progress * 80;
  }

  get percentDone() {
    return this.progress;
  }

  get progressStyleString() {
    return htmlSafe('width: ' + this.percentDone + '%');
  }

  async findOrCreateProjectVersionFileVersion(
    projectFileVersion,
    projectVersion
  ) {
    var projectVersionFileVersions = await this.store.query(
      'project-version-file-version',
      {
        filter: {
          projectFileVersionId: projectFileVersion.id,
          projectVersionId: projectVersion.id,
        },
      }
    );
    var projectVersionFileVersion = projectVersionFileVersions.at(0);
    if (!projectVersionFileVersion) {
      this.log(
        'gonna make a projectVersionFileVersion',
        projectFileVersion,
        projectVersion
      );
      projectVersionFileVersion = await this.store.createRecord(
        'project-version-file-version',
        {
          projectFileVersion: projectFileVersion,
          projectVersion: projectVersion,
        }
      );
      this.log('made it, about to save');
      await projectVersionFileVersion.save();
      this.log(
        'created a projectVersionFileVersion!',
        projectFileVersion.id,
        projectVersion.id,
        projectVersionFileVersion
      );
    } else {
      this.log(
        'found a version',
        projectFileVersion.id,
        projectVersion.id,
        projectVersionFileVersion
      );
    }
    return projectVersionFileVersion;
  }
}
