import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { pushPayload } from 'seshy/utils/push-payload';
import { action } from '@ember/object';
import { hash } from 'rsvp';

export default class AuthenticatedProjectsIndexRoute extends BaseRoute {
  @service store;
  @service currentUser;

  async model(params) {
    let projects = this.store.findAll('project', {
      include:
        'owning-team,teams,user,updator,current-project-version,current-project-version.bounces',
      // TODO: Figure out how to get rid of this. Without it we only get a partial list if you start
      // on the dashboard and then navigate to the projects page.
      reload: true,
    });
    return hash({ projects, teams: this.currentUser.teams });
  }

  async afterModel(model, transition) {
    let teams = model.teams; //.toArray();
    //console.log('afterModel teams = ', teams, teams.length);
    //for (const team of teams.toArray()) {
    for (let i = 0, len = teams.length; i < len; i++) {
      //console.log('i = ', i);
      let team = teams[i];
      //console.log('team = ', team);

      this.subscribeForRefresh(
        'ProjectsChannel',
        { team_id: team.id },
        this.setRefreshAvailable
      );
      this.subscribeForRefresh(
        'BouncesChannel',
        { team_id: team.id },
        this.setRefreshAvailable
      );
      this.subscribeForRefresh(
        'CommentsChannel',
        { team_id: team.id },
        this.setRefreshAvailable
      );
    }
  }

  @action
  routeRefresh() {
    //console.log('routeRefresh');
    this.refresh();
  }
}
