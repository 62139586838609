import EmberRouter from '@ember/routing/router';
import config from 'seshy/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('authenticated', { path: '' }, function () {
    this.route('about');
    this.route('settings');
    this.route('orphaned');
    // all routes that require the session to be authenticated
    this.route('teams', function () {
      this.route('list', function () {});
      this.route('show', { path: ':team_id' }, function () {
        this.route('members', function () {});
        this.route('projects', function () {
          this.route('list', function () {});
        });
        this.route('issues', function () {});
        this.route('storage', function() {});
      });
    });
    this.route('projects', function () {
      this.route('show', { path: ':project_id' }, function () {
        this.route('project-versions', function () {
          this.route('list', function () {});
          this.route('show', { path: ':project_version_id' }, function () {
            this.route('bounces', function () {
              this.route('list');
              this.route('show', { path: ':bounce_id' }, function () {});
            });
            this.route('files');
          });
        });
        this.route('project-files', function () {});
        this.route('teams', function () {});
        this.route('settings');
        this.route('issues', function () {
          this.route('show', { path: ':issue_id' });
          this.route('new');
        });
      });
    });
    this.route('network-monitor');
    this.route('issues', function () {});
  });
});
