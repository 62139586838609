import EmberObject from '@ember/object';
import { DelayPolicy } from 'ember-concurrency-retryable';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import FingerprintMismatchError from '../errors/fingerprint-mismatch-error';
import FileUploadError from '../errors/file-upload-error';
import FilesLockedError from '../errors/files-locked-error';
import { A } from '@ember/array';
import LocalFile from 'seshy/models/local-file';
import ProjectFileSyncOperationV2 from 'seshy/models/project-file-sync-operation-v2';
import { task, timeout } from 'ember-concurrency';

const myDelayPolicy = new DelayPolicy({
  delay: [300, 600, 1200, 2400],
  reasons: [FingerprintMismatchError, FileUploadError],
});

const filesPerBatch = 25;
//const filesPerBatch = 2;

export default class ProjectDirectorySyncOperationV2Model extends EmberObject {
  @service settings;

  type = 'upload';

  projectData = null;
  store = null;

  glob = window.requireNode('glob-promise');

  @tracked activeFileOperations = A([]);
  @tracked fileOperations = A([]);

  @tracked subOperationCount = 0;

  @tracked status = '...';

  customInit(projectData, store, primaryTeam, projectVersionRepository, user) {
    this.projectData = projectData;
    this.store = store;
    this.primaryTeam = primaryTeam;
    this.projectVersionRepository = projectVersionRepository;
    this.user = user;

    this.projectPath = projectData.projectPath;
    this.projectName = projectData.projectName;
    this.projetLocalFileClass = projectData.projetLocalFileClass;
    this.files = projectData.files;
    this.isFirstScan = projectData.isFirstScan;

    this.log('v2 after constructor this = ', this);
  }

  get percentDone() {
    var percent =
      100 - (this.fileOperations.get('length') / this.subOperationCount) * 100;
    if (isNaN(percent)) {
      percent = 0;
    }
    return percent;
  }

  log(...args) {
    if (true || false) {
      console.log("v2", ...args);
    }
  }

  async prepare() {
    this.log('beginning prepare this = ', this);
    this.status = 'Waiting';
    var team = this.primaryTeam;
    var project = await this.findOrCreateProject(team);
    this.project = project;
  }

  async sync() {
    this.log('beginning sync this = ', this);

    if (this.project == null) {
      //console.log('apparently we could not find a project');
      return null;
    }

    if (this.project.archived) {
      //console.log('this project is archived', this.project);
      if (!this.isFirstScan) {
        //console.log('but this is the first scan....');
        this.project.set('projectChangedWhileArchived', true);
      }
      return null;
    }

    this.project.set('projectChangedWhileArchived', false);

    this.status = 'Scanning';


    var checksum = await this.calculateProjectFingerprint();
    var projectVersion = await this.findOrCreateProjectVersion(
      this.project,
      checksum
    );

    if (projectVersion == null) {
      return null;
    }

    this.projectVersion = projectVersion;

    if (!projectVersion.get('uploadComplete')) {
      this.log('about to try to sync a project version')
      // Setting this forces the project to the top of the list
      this.project.set('latestVersionUpdatedAt', new Date());

      this.status = 'Fingerprinting';

      try {
        let filesInProject = [];
        await Promise.all(
          this.files.map(async (filePath) => {
            await this.fingerprintFile.perform(filePath, filesInProject);
          })
        );

        console.log("-----------------------------------------------------")
        console.log("filesInProject: ----- ")
        console.log(filesInProject)
        console.log("-----------------------------------------------------")

        let filesInProjectBatches = [];
        while( filesInProject.length > 0 ){
          filesInProjectBatches.push( filesInProject.splice(0,filesPerBatch) )
        }

        this.status = 'Diffing';

        console.log("filesInProjectBatches: ----- ")
        console.log(filesInProjectBatches)
        console.log("-----------------------------------------------------")

        let filesToUpload = [];
        await Promise.all(filesInProjectBatches.map( async(batch) => {
          await this.doBatchRequest.perform(projectVersion, batch, filesToUpload);
        }))

        console.log("filesToUpload: ----- ")
        console.log(filesToUpload)
        console.log("-----------------------------------------------------")

        this.status = 'Uploading';

        await Promise.all(
          filesToUpload.map(async (file) => {
            await this.handleFile(this.projectPath + '/' + file.path, file.project_file_version_id);
          })
        );

        projectVersion.set('uploadComplete', true);
        await projectVersion.save();
      } catch (err) {
        if (err instanceof FilesLockedError) {
          // do nothing?
        } else {
          console.error('we cauth an unknown error', err);
          throw err;
        }
      } finally {
        await this.project.reload();
      }
    }

    return {
      project: this.project,
      projectVersion: projectVersion,
      localPath: this.projectPath,
    };
  }

  async handleFile(filePath, projectFileVersionId) {
    if (filePath == this.projectPath) {
      // We just bail out since the project itself represents this directory
      return;
    }
    var operation = new ProjectFileSyncOperationV2(
      filePath,
      this.project,
      this.projectPath,
      this.projectVersion,
      this.store,
      projectFileVersionId
    );
    this.subOperationCount += 1;
    this.fileOperations.pushObject(operation);
    try {
      await this.fileSync.perform(operation);

      // Just for testing purposes
      //this.project.set('filesLockedError', true);
      //this.project.set('retryProjectData', this.projectData);
      //throw new FilesLockedError(filePath);
    } catch (err) {
      // TODO: Is this safe? We do this because Ableton will open a file handle for any tracks that are
      // armed. On Mac this results in us uploading an empty file, which doesn't seem to be a big deal.
      // On Windows it seems that node can't even open the file for reading, and so we get an error about
      // it being busy. Thankfully Ableton doesn't seem to keep the main project file open.
      if (err.code == 'EBUSY') {
        console.log('we caught EBUSY for filePath', filePath);
        this.project.set('filesLockedError', true);
        this.project.set('retryProjectData', this.projectData);
        throw new FilesLockedError(filePath);
      } else if (err.code == 'ENOENT') {
        // TODO: Is this safe?
        // It seems like sometimes Ableton will delete one of these weird temp files as we're trying to upload.
        // I guess we just don't care?
        console.log('we caught ENOENT for filePath', filePath);
      } else {
        this.project.set('fileUploadError', true);
        this.project.set('retryProjectData', this.projectData);
        this.project.fileUploadErrorFiles.pushObject({file: filePath, error: err.message});
        console.log(
          'we caught an error trying to upload a file',
          filePath,
          err
        );
        throw err;
      }
    } finally {
      this.fileOperations.removeObject(operation);
    }
  }

  doBatchRequest = task(
    {
      // TODO: Investigate if there are better concurrency settings here. In my initial testing
      // anything longer than 1 seems to make the server responses much slower.
      //maxConcurrency: this.settings.get('fileUploadMax'),
      maxConcurrency: 1,
      enqueue: true,
      retryable: myDelayPolicy,
    },
    async (projectVersion, batch, filesToUpload) => {
      let projectVersionFileVersionBatch = await this.store.createRecord('project-version-file-version-batch',{
        projectVersion: projectVersion,
        files: batch
      });
      await projectVersionFileVersionBatch.save();
      filesToUpload.push(...projectVersionFileVersionBatch.filesToUpload)
    }
  );


  fingerprintFile = task(
    {
      maxConcurrency: this.settings.get('fileUploadMax'),
      enqueue: true,
      retryable: myDelayPolicy,
    },
    async (filePath, filesInProject) => {
      console.log('fingerprintFile', filePath);
      let localFile = new LocalFile(filePath);
      let fingerprint = await localFile.fingerprint();
      let fileType = await localFile.fileType();
      let projectFilePath = filePath.replace(this.projectPath + '/', '');
      filesInProject.push({
        path: projectFilePath,
        checksum: fingerprint,
        fileType: fileType
      })
    }
  );

  // TODO : this is the most minimal change I could make to add some concurrency control to uploads.
  // There are probably better ways to handle this. And I'm not entirely sure that it's a good idea
  // to use ember-concurrency in a POJO, but it seems to work...
  fileSync = task(
    {
      maxConcurrency: this.settings.get('fileUploadMax'),
      enqueue: true,
      retryable: myDelayPolicy,
    },
    async (operation) => {
      this.log('starting sync');
      this.activeFileOperations.pushObject(operation);
      try {
        await operation.sync();
      } finally {
        this.activeFileOperations.removeObject(operation);
        this.log('ending sync');
      }
    }
  );

  async calculateProjectFingerprint() {
    return await this.projetLocalFileClass.calculateProjectFingerprint(
      this.projectPath
    );
  }

  async findOrCreateProject(team) {
    //console.log('starting findOrCreateProject');
    var fs = window.require('fs');

    // First we look to see if we already know about this path in the repository
    var pData = this.projectVersionRepository.findProjectDataByLocalPath(
      this.projectPath,
      true
    );
    //console.log('pData = ', pData);
    // If we don't know about it by path, maybe it's because the user has moved
    // the project within their uplaod folder. So we see if the repo knows about
    // a project with the same name and checksum.
    if (!pData) {
      var checksum = await this.calculateProjectFingerprint();
      pData =
        this.projectVersionRepository.findProjectDataByProjectNameAndChecksum(
          this.projectName,
          checksum
        );
      if (pData) {
        //console.log('-------');
        //console.log('we found a project by name and checksum!');
        //console.log('-------');
        //
        // If the local path in the pData is different than the path we're looking for
        // that means we're in the name/checksum fallback path. In which case we check
        // to see if the project referenced in the pData still exists. If it does we
        // assume that this is an intentional "duplicate" by the user, and we create
        // a new project in Seshy.
        if (this.projectPath != pData.localPath) {
          //console.log(
          //'paths do not match, going to see if the pData project still exists'
          //);
          if (fs.existsSync(pData.localPath)) {
            //console.log(
            //'pData localPath still exists we should create a new project'
            //);
            // Now we assign pData as null so we force creation of a new project below
            pData = null;
          } else {
            //console.log(
            //'pData localPath does not exist we can assume this project moved'
            //);
          }
        }
      }
    }

    //console.log('by name & checksum pData = ', pData);

    if (pData) {
      var projectId = pData.projectId;
      //console.log('projectId = ', projectId);
      try {
        var project = await this.store.findRecord('project', projectId);
        //console.log(' we found the project from the repo!!!!!!!! ');
        return project;
      } catch (error) {
        console.error('caught an error!');
        console.error(error);
        let errorCause = error.errors[0];
        if (errorCause.code == 404) {
          //console.log('it was a 404');
          this.projectVersionRepository.markMissingProjectByLocalPath(
            this.projectPath
          );
          return null;
        } else {
          throw error;
        }
      }
    }

    var project = await this.createProjectByName(team);

    // TODO: Is there a way to mark a project directory directly, instead of maintaining
    // an external manifest/repo?
    // We push a project into the repo immediately so that we keep track of our newly
    // created project in case we don't complete the upload during this instance of Seshy.
    // This make it so that when the app restarts we don't create a duplicate project.
    this.projectVersionRepository.pushProjectData(
      project,
      null,
      this.projectPath
    );

    return project;
  }

  async createProjectByName(team) {
    var project = await this.store.createRecord('project', {
      name: this.projectName,
      daw: this.projectData.daw,
      team: team,
      owningTeam: team,
      versionNumber: 1,
      latestVersionSizeInBytes: 0,
      user: this.user,
      updator: this.user,

      latestVersionUpdatedAt: (new Date()).toISOString()
    });

    try{
      await project.save();
    }catch(error){
      project.set('projectNotCreatedError', true);
      project.set('retryProjectData', this.projectData);
      //throw new ProjectNotCreatedError(project.name);
      throw error;
    }


    team.get('projects').push(project);

    //console.log('created a record!', this.projectName);

    return project;
  }

  async findOrCreateProjectVersion(project, checksum) {
    var versions = await this.store.query('project-version', {
      filter: {
        projectId: project.id,
        projectDataChecksum: checksum,
      },
    });
    var version = versions.at(0);
    if (version) {
      return version;
    }

    var projectVersions = await project.get('projectVersions');
    this.log('gonna make a version', project, checksum);
    version = await this.store.createRecord('project-version', {
      project: project,
      projectDataChecksum: checksum,
    });
    this.log('made it, about to save');
    try {
      await version.save();
    } catch (error) {
      console.error('caught an error!');
      console.error(error);
      let errorCause = error.errors[0];

      console.log('errorCause = ', errorCause);
      // NOTE: We DO NOT directly handdle a '401 Unauthorized' response here. For that we
      // fall into the else block at the bottom and rethrow the error. The reason for that
      // is that 402 & 403 are project specific errors, where a 401 means "you're not signed in".
      // So for that case we bubble up the error to the upload manager so that it doesn't try
      // to populate the projectVersionRepository with bad data. The 401 will also cause the
      // data adapter to redirect to the login page.
      if (errorCause.status == '403') {
        console.log('it was a 403');
        //TODO: Mark it or something
        this.project.set('apiProjectUnauthorized', true);
        this.project.set('retryProjectData', this.projectData);
        this.projectVersionRepository.markUnauthorizedProjectByLocalPath(
          this.projectPath
        );
        return null;
      } else if (errorCause.status == '402') {
        console.log('it was a 402');
        //TODO: Mark it or something
        this.project.set('apiPaymentRequired', true);
        this.project.set('retryProjectData', this.projectData);
        this.projectVersionRepository.markPaymentRequiredByLocalPath(
          this.projectPath
        );
        return null;
      } else {
        throw error;
      }
    }

    if (projectVersions) {
      projectVersions.push(version);
    }

    // set a couple of things that help with adding descriptions
    version.set('brandNewProjectVersion', true);
    this.project.set('brandNewProjectVersion', version);

    this.log('created a version!', project.id, checksum, version);

    return version;
  }
}
