/* import __COLOCATED_TEMPLATE__ from './manage-subscription-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from 'seshy/config/environment';
import { inject as service } from '@ember/service';

export default class ManageSubscriptionButtonComponent extends Component {
  @service platform;

  @action
  openSubscriptionPage(e) {
    e.preventDefault();
    var page =
      ENV.api.webhost +
      `/account/teams/${this.args.team.obfuscatedId}/billing/subscriptions`;
    console.log('yo', page);
    if (this.platform.isNative) {
      let shell = require('electron').shell;
      shell.openExternal(page);
    } else {
      window.open(page);
    }
  }
}
